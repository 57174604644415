import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Authorize = () => {
  const location = useLocation();
  const navigate = useNavigate(); 

  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    const query = getQueryParams(location.search);
    const token = query.get('token');
    const username = query.get('username');

    if (token && username) {
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("user", JSON.stringify(username));
      localStorage.setItem("passwordExpired",false)
      var functions= ["CHIUSO","SEARCH","RICHIESTA_ON_BOARDING","RICHIESTA_DOCUMENTI_OPZIONALI","DUPLICA_ADV","UPLOAD_DOCUMENTI_BANCA","CHIUDI","NUOVA_ADV","UPLOAD_DOCUMENTI_IDENTITA","UTENTE_BASE"]
      localStorage.setItem("functions",JSON.stringify(functions))
      window.location.href = '/';
    }
  }, [location, navigate]);

  return (
    <div>
      <p>Autenticazione in corso... Verifica credenziali.</p>
    </div>
  );
};

export default Authorize;
