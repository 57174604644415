import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Stack,
  Grid,
  TextField,
  MenuItem,
  Tooltip,
  Autocomplete,
  Checkbox,
  Paper,
  ListItemText,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import OnlyWithFunction, { isValidUser } from '../../OnlyWithFunctions';
import { StyledButton } from '../../UI/StyledButton';
import { StatiPratica } from '../../utils/StatiPratica';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AdeguataVerificaFormModal({
  errorState,
  disabledButton,
  loading,
  errorCF,
  onClick,
  defaultValues,
  idNodoPratica,
  responseDettaglioRelazione,
  handleViewModal,
}) {
  const theme = useTheme();
  const location = useLocation();
  const isAmlDesk = isValidUser('SUPPORTO_AML_DESK');
  const isAml = isValidUser('SUPPORTO_FUNZIONE_AML');
  const userCanAssign = isValidUser('ASSEGNA_PRATICA');

  const [listOptionalDocNames, setListOptionalDocNames] = useState([]);
  const [verificaRafforzataInRelazioneA, setVerificaRafforzataInRelazioneA] = useState([]);
  const [listaAutomaticaARAlt, setListaAutomaticaARAlt] = useState([]);
  const [listaAutomaticaMRAlt, setListaAutomaticaMRAlt] = useState([]);
  const [listaManualeUtenteBase, setListaManualeUtenteBase] = useState([]);
  const [listaManualeUtenteBaseAlt, setListaManualeUtenteBaseAlt] = useState([]);
  const [tipologiaCliente, setTipologiaCliente] = useState([]);
  const [daAssegnareA, setDaAssegnareA] = useState([]);
  const [listRelazioneA, setListRelazioneA] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [assegnareA, setAssegnareA] = useState('');
  const [statoRichiesta, setStatoRichiesta] = useState(
    location.state?.selectedRowData.entry.properties['folder_model:statorichiesta'],
  );
  const initialState = {
    idNodoPratica: idNodoPratica ? idNodoPratica : '',
    NDG: defaultValues ? defaultValues['folder_model:NDG'] : '',
    indirizzo: defaultValues ? defaultValues['folder_model:indirizzo'] : '',
    Citta: defaultValues ? defaultValues['folder_model:citta'] : '',
    nazione: defaultValues ? defaultValues['folder_model:nazione'] : '',
    ragioneSociale: defaultValues ? defaultValues['folder_model:ragioneSociale'] : '',
    codiceFiscale: defaultValues ? defaultValues['folder_model:codiceFiscale'] : '',
    nomePratica: defaultValues ? defaultValues['folder_model:nomePratica'] : '',
    tipologiaCliente: defaultValues ? defaultValues['folder_model:tipologiaCliente'] : '',
    tipologiaDiRichiesta: defaultValues ? defaultValues['folder_model:tipologiaDiRichiesta'] : '',
    tipologiaOperativita: defaultValues ? defaultValues['folder_model:tipologiaOperativita'] : '',
    relazione: defaultValues ? defaultValues['folder_model:relazione'] : '',
    motivazioneRichiesteOpzionali: defaultValues ? defaultValues['folder_model:motivazioneRichiesteOpzionali'] : '',
    documentiOpzionaliVari: defaultValues ? defaultValues['folder_model:documentiOpzionaliVari'] : '',
    daAssegnare: defaultValues['folder_model:daAssegnare'],
    dettaglioRelazione: defaultValues ? defaultValues['folder_model:dettaglioRelazione'] : '',
    tipoInserimento: defaultValues ? defaultValues['folder_model:tipoInserimento'] : '',
    dettaglioKey: defaultValues ? defaultValues['folder_model:dettaglioKey'] : '',
    statoRichiesta: defaultValues ? defaultValues['folder_model:statorichiesta'] : '',
    gruppoUtenteCreatore: defaultValues ? defaultValues['folder_model:gruppoUtenteCreatore'] : '',
  };
  useEffect(() => {
    const loadDataFromLocalStorage = () => {
      try {
        setListOptionalDocNames(JSON.parse(localStorage.getItem('DocumentiOpzionaliVari')) || []);
        setVerificaRafforzataInRelazioneA(JSON.parse(localStorage.getItem('VerificaRafforzataInRelazioneA')) || []);
        setListaAutomaticaARAlt(["Apertura rapporto continuativo (KYC)", "Monitoraggio Rapporto Automatico", "ADVR scaduta primo anno", "ADVR scaduta anni successivi", "Altro"]);
        setListaAutomaticaMRAlt(["Apertura rapporto continuativo (KYC)", "Monitoraggio Rapporto Automatico", "ADVR scaduta primo anno", "ADVR scaduta anni successivi", "Altro"]);
        setListaManualeUtenteBase(JSON.parse(localStorage.getItem('MotivazioniDellAdeguataVerificaRafforzataArUtenteBase')));
        setListaManualeUtenteBaseAlt(["Sospetto di riciclaggio", "Operazione occasionale", "Esito Monitoraggio", "Richiesta UIF", "Sequestro", "Esibizione atti e documenti", "Precedente SOS", "Altro"]);
        setTipologiaCliente(JSON.parse(localStorage.getItem('TipologiaCliente')) || []);
        setDaAssegnareA(JSON.parse(localStorage.getItem('DaAssegnareA')) || []);
        setDataLoaded(true);
      } catch (error) {
        console.error('Errore nel parsing dei dati dal localStorage:', error);
      }
    };

    loadDataFromLocalStorage();
  }, []);

  useEffect(() => {
    if (initialState?.tipologiaDiRichiesta === 'ADV ALTO RISCHIO AUTOMATICA') {
      setListRelazioneA(listaAutomaticaARAlt);
    } else if (initialState?.tipologiaDiRichiesta === 'ADV MEDIO RISCHIO AUTOMATICA') {
      setListRelazioneA(listaAutomaticaMRAlt);
    } else if (initialState?.tipologiaDiRichiesta === 'ADV ALTO RISCHIO MANUALE') {
      setListRelazioneA(listaManualeUtenteBaseAlt);
    } else if (initialState?.tipologiaDiRichiesta === 'ADV MEDIO RISCHIO MANUALE') {
      setListRelazioneA(listaManualeUtenteBaseAlt);
    }
  }, [
    listaAutomaticaARAlt,
    listaAutomaticaMRAlt,
    listaManualeUtenteBase,
    listaManualeUtenteBaseAlt,
    initialState?.tipologiaDiRichiesta,
  ]);



  const [existingOptionalDocName, setExistingOptionalDocName] = useState(
    defaultValues && defaultValues['folder_model:documentiOpzionaliVari']
      ? defaultValues['folder_model:documentiOpzionaliVari']
      : [],
  );

  const [existingRelazione, setExistingRelazione] = useState(
    defaultValues && defaultValues['folder_model:relazione']
      ? defaultValues['folder_model:relazione'].split(', ')
      : [],
  );

  const [showNote, setShowNote] = useState(false);
  const [showDettagliorelazione, setShowDettagliorelazione] = useState(false);
  const [formValues, setFormValues] = useState(initialState);
  const [isHighRiskOrMediumRisk, setIsHighRiskOrMediumRisk] = useState(false);
  const [errorNDG, setErrorNDG] = useState(false);

  const navigate = useNavigate();

  const handleCloseModal = () => setCloseModal(!closeModal);
  const handleEsciModal = () => {
    window.location.reload(true);
  };

  const handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;

    let newValue = value;
    if ((name === 'NDG' || name === 'ragioneSociale') && value.trim() === '') {
      newValue = '';
    }

    setFormValues({
      ...formValues,
      [name]: newValue,
    });

    if (event.target.name === 'tipologiaDiRichiesta' && event.target.value === 'ADV ALTO RISCHIO AUTOMATICA') {
      setListRelazioneA(listaAutomaticaARAlt);
    } else if (event.target.name === 'tipologiaDiRichiesta' && event.target.value === 'ADV MEDIO RISCHIO AUTOMATICA') {
      setListRelazioneA(listaAutomaticaMRAlt);
    } else if (event.target.name === 'tipologiaDiRichiesta' && event.target.value === 'ADV ALTO RISCHIO MANUALE') {
      setListRelazioneA(listaManualeUtenteBaseAlt);
    }
  };

  const multiChange = event => {
    const {
      target: { value },
    } = event;

    setExistingOptionalDocName(
      typeof value === 'string' ? value.split(',') : value,
    );
    setShowNote(value.length > 0);
  };

  const multiChangeMotivazioni = event => {
    const {
      target: { value },
    } = event;

    setExistingRelazione(
      typeof value === 'string' ? value.split(',') : value,
    );

    if (value.includes('Altro') || value.includes('Esito Monitoraggio')) {
      setShowDettagliorelazione(true);
    } else {
      setShowDettagliorelazione(false);
      setFormValues(prev => ({
        ...prev,
        dettaglioRelazione: '',
      }));
    }
  };

  useEffect(() => {
    if (existingOptionalDocName && existingOptionalDocName.length !== 0) {
      setShowNote(true);
    } else if (existingOptionalDocName && existingOptionalDocName.length === 0) {
      setShowNote(false);
    }
  }, [existingOptionalDocName]);

  useEffect(() => {
    if (existingRelazione.includes('Altro') || existingRelazione.includes('Esito Monitoraggio')) {
      setShowDettagliorelazione(true);
    } else {
      setShowDettagliorelazione(false);
    }
  }, [existingRelazione]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      documentiOpzionaliVari: existingOptionalDocName,
    });
  }, [existingOptionalDocName]);

  useEffect(() => {
    if (initialState?.tipologiaDiRichiesta === 'ADV ALTO RISCHIO AUTOMATICA') {
      setListRelazioneA(listaAutomaticaMRAlt);
      setIsHighRiskOrMediumRisk(true);
    } else if (initialState?.tipologiaDiRichiesta === 'ADV MEDIO RISCHIO AUTOMATICA') {
      setListRelazioneA(listaAutomaticaMRAlt);
      setIsHighRiskOrMediumRisk(true);
    } else if (initialState?.tipologiaDiRichiesta === 'ADV ALTO RISCHIO MANUALE') {
      setListRelazioneA(listaManualeUtenteBaseAlt);
    }
  }, [initialState?.tipologiaDiRichiesta]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      relazione: existingRelazione,
    });
  }, [existingRelazione]);

  return (
    <Stack
      sx={{ backgroundColor: '#FFFFFF' }}
      alignContent="space-center"
      spacing={{ xs: 10, md: 2, lg: 2, xl: 2 }}
    >
      {dataLoaded ? (
        <>
          <Grid
            container
            padding="20px"
            mt={{ xs: 18, lg: 8, xl: -3 }}
            spacing={{ xs: 20, md: 1 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            justifyItems="center"
          >
            <Grid item xs={3}>
              <TextField
                disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                sx={{ width: '95%' }}
                onChange={handleChange}
                name={'NDG'}
                error={!isHighRiskOrMediumRisk && errorState && (formValues.NDG === undefined || formValues.NDG === '')}
                value={formValues.NDG}
                id="standard-basic"
                label={isHighRiskOrMediumRisk ? 'NDG' : 'NDG *'}
                variant="standard"
                margin="dense"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{ mt: 1, width: '95%' }}
                onChange={handleChange}
                disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                name={'ragioneSociale'}
                error={errorState && (formValues.ragioneSociale === undefined || formValues.ragioneSociale === '' || formValues.ragioneSociale.length > 100)}
                value={formValues.ragioneSociale}
                required
                id="standard-basic"
                label="Ragione Sociale"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{ mt: 1, width: '95%' }}
                onChange={handleChange}
                disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                name={'codiceFiscale'}
                error={errorState && (formValues.codiceFiscale === undefined || formValues.codiceFiscale === '')}
                value={formValues.codiceFiscale}
                id="standard-basic"
                label="Codice Fiscale *"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{ mt: 1, width: '95%' }}
                onChange={handleChange}
                disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                name={'nazione'}
                value={formValues.nazione}
                id="standard-basic"
                label="Nazione"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{ mt: 1, width: '95%' }}
                onChange={handleChange}
                disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                name={'indirizzo'}
                value={formValues.indirizzo}
                id="standard-basic"
                label="Indirizzo"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{ mt: 1, width: '95%' }}
                onChange={handleChange}
                disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                name={'Citta'}
                value={formValues.Citta}
                id="standard-basic"
                label="Città"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{ mt: 1, width: '95%' }}
                onChange={handleChange}
                disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                name={'nomePratica'}
                value={formValues.nomePratica}
                id="standard-basic"
                label="Nome Pratica"
                variant="standard"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                select
                sx={{ mt: 1, width: '95%' }}
                variant="standard"
                label="Tipologia cliente"
                onChange={handleChange}
                disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                value={formValues.tipologiaCliente}
                inputProps={{
                  name: 'tipologiaCliente',
                  id: 'uncontrolled-native',
                }}
              >
                {tipologiaCliente.map(name => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            padding="10px"
            paddingRight="20px"
            justifyContent={'end'}
            alignContent={'center'}
            sx={{
              backgroundColor: theme.palette.blue.light,
            }}
          >
            <Grid
              container
              paddingBottom="10px"
              columns={{ xs: 4, sm: 8, md: 12 }}
              spacing={3}
              item
              xs={12}
              padding="10px"
            >
              <Grid item xs={4}>
                <Tooltip title={formValues.tipologiaDiRichiesta} placement="top">
                  <TextField
                    select
                    helperText="Tipologia di richiesta"
                    sx={{ width: '100%' }}
                    variant="standard"
                    value={formValues.tipologiaDiRichiesta}
                    onChange={handleChange}
                    disabled
                    margin="dense"
                    inputProps={{
                      name: 'tipologiaDiRichiesta',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <MenuItem value="ADV ALTO RISCHIO AUTOMATICA">ADV ALTO RISCHIO AUTOMATICA</MenuItem>
                    <MenuItem value="ADV MEDIO RISCHIO AUTOMATICA">ADV MEDIO RISCHIO AUTOMATICA</MenuItem>
                    <MenuItem value="ADV ALTO RISCHIO MANUALE">ADV ALTO RISCHIO MANUALE</MenuItem>
                    <MenuItem value="ADV MEDIO RISCHIO MANUALE">ADV MEDIO RISCHIO MANUALE</MenuItem>
                  </TextField>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <OnlyWithFunction acceptedFunction="RICHIESTA_DOCUMENTI_OPZIONALI">
                  <Tooltip
                    title={
                      existingOptionalDocName && existingOptionalDocName.length !== 0
                        ? existingOptionalDocName.join(', ')
                        : ''
                    }
                    placement="top"
                  >
                    <TextField
                      helperText="Documenti (Opzionali) Vari"
                      variant="standard"
                      size="none"
                      sx={{ width: '100%' }}
                      disabled={statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                      id="demo-multiple-checkbox"
                      margin="dense"
                      select
                      MenuProps={MenuProps}
                      SelectProps={{
                        multiple: true,
                        value: existingOptionalDocName,
                        onChange: multiChange,
                        renderValue: selected => selected.join(', '),
                      }}
                    >
                      {listOptionalDocNames.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={existingOptionalDocName.indexOf(name) > -1} />
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </OnlyWithFunction>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  sx={{ width: '100%' }}
                  variant="standard"
                  helperText="Verifica rafforzata in relazione al seguente prodotto"
                  onChange={handleChange}
                  disabled={isValidUser('SUPPORTO_FUNZIONE_AML') || isValidUser('UTENTE_INTERNO') && statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete}
                  margin="dense"
                  value={formValues.tipologiaOperativita}
                  inputProps={{
                    name: 'tipologiaOperativita',
                    id: 'uncontrolled-native',
                  }}
                >
                  {verificaRafforzataInRelazioneA.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <Tooltip
                  title={
                    !isFieldFocused && existingRelazione && existingRelazione.length !== 0
                      ? existingRelazione.join(', ')
                      : ''
                  }
                  placement="top"
                  disableHoverListener={isFieldFocused}
                >
                  <TextField
                    helperText="Motivazioni dell’adeguata verifica"
                    sx={{ width: '100%' }}
                    disabled={isValidUser('SUPPORTO_FUNZIONE_AML') || isValidUser('UTENTE_INTERNO') && statoRichiesta === StatiPratica.checkOutOperatore || statoRichiesta === StatiPratica.infoComplete || formValues.tipoInserimento.toLowerCase() === 'automatica'}
                    variant="standard"
                    id="demo-multiple-checkbox"
                    select
                    MenuProps={MenuProps}
                    SelectProps={{
                      multiple: true,
                      value:
                        typeof existingRelazione === 'string' && existingRelazione
                          ? existingRelazione.split(',')
                          : existingRelazione,
                      onChange: multiChangeMotivazioni,
                      renderValue: selected => selected.join(', '),
                    }}
                    onFocus={() => setIsFieldFocused(true)}
                    onBlur={() => setIsFieldFocused(false)}
                    error={errorState && (formValues.relazione === undefined || formValues.relazione === '')}
                    value={formValues.relazione}
                    inputProps={{
                      name: 'relazione',
                      id: 'uncontrolled-native',
                    }}
                  >
                    {listRelazioneA.map(name => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={existingRelazione.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                {((isAml && !isAmlDesk) || userCanAssign) ? (
                  <Autocomplete
                    name={'daAssegnare'}
                    disabled={false}
                    noOptionsText={'Nessun valore corrispondente'}
                    sx={{ width: '100%' }}
                    id="standard-basic"
                    value={formValues.daAssegnare}
                    label="Da assegnare a"
                    required
                    variant="standard"
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      setFormValues({ ...formValues, daAssegnare: newValue });
                    }}
                    options={daAssegnareA !== null ? daAssegnareA : []}
                    inputValue={assegnareA || ''}
                    onInputChange={(event, newInputValue) => {
                      setAssegnareA(newInputValue);
                    }}
                    disablePortal
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={handleChange}
                        helperText="Da Assegnare a"
                        fullWidth
                        required
                        variant="standard"
                        autoFocus
                        margin="none"
                        error={errorState && (formValues.daAssegnare === null || formValues.daAssegnare === '')}
                      />
                    )}
                  />
                ) : (!isAml && isAmlDesk && !userCanAssign) ? (
                  <Autocomplete
                    name={'daAssegnare'}
                    disabled={true}
                    noOptionsText={'Nessun valore corrispondente'}
                    sx={{ width: '100%' }}
                    id="standard-basic"
                    value={formValues.daAssegnare}
                    label="Da assegnare a"
                    required
                    variant="standard"
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      setFormValues({ ...formValues, daAssegnare: newValue });
                    }}
                    options={daAssegnareA !== null ? daAssegnareA : []}
                    inputValue={assegnareA || ''}
                    onInputChange={(event, newInputValue) => {
                      setAssegnareA(newInputValue);
                    }}
                    disablePortal
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={handleChange}
                        helperText="Da Assegnare a"
                        fullWidth
                        required
                        variant="standard"
                        autoFocus
                        margin="none"
                        error={errorState && (formValues.daAssegnare === null || formValues.daAssegnare === '')}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    name={'daAssegnare'}
                    disabled={true}
                    noOptionsText={'Nessun valore corrispondente'}
                    sx={{ width: '100%' }}
                    id="standard-basic"
                    value={formValues.daAssegnare}
                    label="Da assegnare a"
                    required
                    variant="standard"
                    onChange={(event, newValue) => {
                      event.preventDefault();
                      setFormValues({ ...formValues, daAssegnare: newValue });
                    }}
                    options={daAssegnareA !== null ? daAssegnareA : []}
                    inputValue={assegnareA || ''}
                    onInputChange={(event, newInputValue) => {
                      setAssegnareA(newInputValue);
                    }}
                    disablePortal
                    renderInput={params => (
                      <TextField
                        {...params}
                        onChange={handleChange}
                        helperText="Da Assegnare a"
                        fullWidth
                        required
                        variant="standard"
                        autoFocus
                        margin="none"
                        error={errorState && (formValues.daAssegnare === null || formValues.daAssegnare === '')}
                      />
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
            {showNote && (
              <textarea
                required
                aria-label="empty textarea"
                defaultValue={formValues.motivazioneRichiesteOpzionali}
                placeholder="Motivazioni richieste opzionali*"
                onChange={handleChange}
                name={'motivazioneRichiesteOpzionali'}
                minRows={3}
                style={{
                  margin: '2px',
                  width: '100%',
                  maxWidth: '100%',
                  height: '100px', // Altezza fissa
                  maxHeight: '100px', // Altezza massima
                  minWidth: '200px', // Larghezza minima
                  overflowY: 'auto', // Scroll verticale
                  borderColor: 'black',
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {showDettagliorelazione && (
              <textarea
                defaultValue={formValues.dettaglioRelazione}
                placeholder="Dettaglio Relazione"
                onChange={event => setFormValues(prev => ({ ...prev, dettaglioRelazione: event.target.value }))}
                minRows={3}
                style={{
                  margin: '2px',
                  width: '100%',
                  maxWidth: '100%',
                  height: '100px', // Altezza fissa
                  maxHeight: '100px', // Altezza massima
                  minWidth: '200px', // Larghezza minima
                  overflowY: 'auto', // Scroll verticale
                  borderColor: 'black',
                }}
              />
            )}
          </Grid>
            </Grid>
            <Grid alignContent="end" item xs={12}>
              <StyledButton
                disabled={disabledButton}
                onClick={(event, params) =>
                  onClick(event, {
                    ...formValues,
                    relazione: existingRelazione.join(', '),
                  })
                }
                sx={{ margin: 'auto', marginTop: '-1em', ml: '10px' }}
              >
                SALVA
              </StyledButton>
            </Grid>
            <Grid alignContent="end" item xs={12}>
              <StyledButton
                sx={{ margin: 'auto', marginTop: '0.25em', ml: '10px' }}
                onClick={handleViewModal}
              >
                ESCI
              </StyledButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </Stack>
  );
}

export default AdeguataVerificaFormModal;
