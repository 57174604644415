import React, { useState } from 'react'
import { AppBar } from '@mui/material'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import OnlyWithFunction from '../OnlyWithFunctions'
import DuplicaADVModal from './DuplicaADVModal'
import { Link } from 'react-router-dom'

import TopLogoDiv from './TopLogoDiv'
import { AuthContext } from '../App'

import { useNavigate } from 'react-router-dom'

import { useContext } from 'react'

import { useTheme } from '@mui/material/styles'
import { StyledButton } from './StyledButton'

import { useQueryClient } from '@tanstack/react-query'
import { logout } from '../services/logout'

const Header = props => {
  const { state: AuthState, dispatch } = useContext(AuthContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const handleLogout = event => {
    event.preventDefault()
    navigate('/')
    queryClient.removeQueries()
    dispatch({ type: 'LOGOUT', payload: { isAuthenticated: false } })
    logout().then(res => { console.log("res per logout:", res) })
    localStorage.clear()
  }

  function stringAvatar(name) {
    return {
      children: `${name.charAt(0).toUpperCase()}`,
    }
  }
  const [openModalDuplicaAdv, setOpenModalDuplicaAdv] = useState(false)
  const clickDuplicaAdvButton = () => {
    setOpenModalDuplicaAdv(!openModalDuplicaAdv)
  }
  const handleDuplicaAdvModalClose = () => {
    setOpenModalDuplicaAdv(false)
  }

  return (
    <div
      style={{
        padding: 0,
        flex: '0 1 auto',
      }}
    >
      {AuthState.isAuthenticated && <TopLogoDiv />}
      <AppBar
        xs={12}
        position="static"
        elevation={0}
        sx={{
          display: 'flex',
          border: 'solid 1px',
          bgcolor: 'white',
          width: '100% ',
          height: '6em',
          alignItems: 'flex-end',
        }}
      >
        <Grid container direction="row" mt="3rem">
          <Grid xs={4} item sx={{ borderBottom: 'solid 10px #19668C ', width:'100%' }} />
          <Grid xs={8} item sx={{ borderBottom: 'solid 10px #19668C ',width:'100%' }}>
            {props.header}
          </Grid>
          {AuthState.isAuthenticated && (
            <>
              <Grid container alignItems="flex-end">
                <Grid
                  xs={12}
                  item
                  sx={{ height: '3rem', marginTop: '0.3em' }}
                  justifyItems={'center'}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignContent="center"
                  >
                    {/* <Grid margin="0.5em" item xs={4}>
                      {props.showHome && (
                        <StyledButton
                          sx={{
                            color: theme.palette.black.main,
                            backgroundColor: theme.palette.grey.light,
                            border: 'none',
                          }}
                          component={Link}
                          to="/"
                        >
                          <Typography variant="caption">HOME</Typography>
                        </StyledButton>
                      )}
                      {props.showRichiesta && (
                        <OnlyWithFunction acceptedFunction="NUOVA_ADV">
                          <StyledButton
                            sx={{
                              marginLeft: '5px',
                              color: theme.palette.black.main,
                              backgroundColor: theme.palette.grey.light,
                              border: 'none',
                            }}
                            component={Link}
                            to="/nuova-richiesta"
                          >
                            <Typography variant="caption">
                              NUOVA RICHIESTA
                            </Typography>
                          </StyledButton>
                        </OnlyWithFunction>
                      )}
                      {props.showDuplicaAdv && (
                        <StyledButton
                          onClick={() => clickDuplicaAdvButton()}
                          sx={{
                            marginLeft: '15px',
                            color: theme.palette.black.main,
                            backgroundColor: theme.palette.grey.light,
                            border: 'none',
                          }}
                        >
                          <Typography
                            variant="caption"
                            color="black"
                            sx={{ fontSize: 10 }}
                          >
                            <b>NUOVA</b> ADVR
                          </Typography>
                          <AddIcon
                            sx={{
                              color: theme.palette.blue.main,
                              fontSize: 20,
                            }}
                          ></AddIcon>
                        </StyledButton>
                      )}
                    </Grid> */}

                    {/* <Grid item container xs={8} justifyContent="end">
                      <Grid
                        margin="0.5em"
                        item
                        xs={3}
                        alignItems="space-around"
                        direction={'row'}
                      >
                        {props.showLogout === false ? null : (
                          <StyledButton
                            sx={{
                              marginLeft: '20px',
                              color: theme.palette.black.main,
                              backgroundColor: theme.palette.grey.light,
                              border: 'none',
                            }}
                            component={Link}
                            to="/"
                            onClick={handleLogout}
                          >
                            <Typography variant="caption">
                              DISCONNETTI
                            </Typography>
                          </StyledButton>
                        )}
                        {props.showProfile === false ? null : (
                          <StyledButton
                            component={Link}
                            to="/profilo-utente"
                            sx={{
                              marginLeft: '20px',
                              color: theme.palette.black.main,
                              backgroundColor: theme.palette.grey.light,
                              border: 'none',
                            }}
                          >
                            <Typography variant="caption">
                              {JSON.parse(localStorage.getItem('user'))}
                            </Typography>
                          </StyledButton>
                        )}
                      </Grid>
                    </Grid> */}
                    <Box
                      sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        // maxWidth: '1500px',

                        display: 'flex',
                        mt: '5px',
                      }}
                    >
                      <Box
                        sx={{
                          flexDirection: 'row',
                          // justifyContent: 'space-between',
                          width: '100%',
                          // maxWidth: '1500px',

                          display: 'flex',
                          mt: '5px',
                        }}
                      >
                        {props.showHome && (
                          <StyledButton
                            sx={{
                              color: theme.palette.black.main,
                              backgroundColor: theme.palette.grey.light,
                              border: 'none',
                              ml: '10px',
                            }}
                            component={Link}
                            to="/"
                          >
                            <Typography variant="caption">HOME ADV</Typography>
                          </StyledButton>
                        )}
                        {props.showDashMonitoraggio && (
                          
                          <StyledButton
                            sx={{
                              color: theme.palette.black.main,
                              backgroundColor: theme.palette.grey.light,
                              border: 'none',
                              ml: '10px',
                            }}
                            component={Link}
                            to="/monitoring"
                          >
                            <Typography variant="caption">HOME MONITORAGGIO</Typography>
                          </StyledButton>
                        )}
                        {props.showRichiesta && (
                          <OnlyWithFunction acceptedFunction="NUOVA_ADV">
                            <StyledButton
                              sx={{
                                marginLeft: '10px',
                                color: theme.palette.black.main,
                                backgroundColor: theme.palette.grey.light,
                                border: 'none',
                              }}
                              component={Link}
                              to="/nuova-richiesta"
                            >
                              <Typography variant="caption">
                                NUOVA RICHIESTA
                              </Typography>
                            </StyledButton>
                          </OnlyWithFunction>

                        )}
                        {props.showStatistiche && (

                          <OnlyWithFunction acceptedFunction="NUOVA_ADV">
                            <StyledButton
                              sx={{
                                marginLeft: '10px',
                                color: theme.palette.black.main,
                                backgroundColor: theme.palette.grey.light,
                                border: 'none',
                              }}
                              component={Link}
                              to="/statistiche"
                            >
                              <Typography variant="caption">
                                STATISTICHE
                              </Typography>
                            </StyledButton>
                          </OnlyWithFunction>)}
                        {props.showAccounting && (

                          <OnlyWithFunction acceptedFunction="UTENTE_INTERNO">
                            <StyledButton
                              sx={{
                                marginLeft: '10px',
                                color: theme.palette.black.main,
                                backgroundColor: theme.palette.grey.light,
                                border: 'none',
                              }}
                              component={Link}
                              to="/accounting"
                            >
                              <Typography variant="caption">
                                ACCOUNTING
                              </Typography>
                            </StyledButton>
                          </OnlyWithFunction>)}
                        {props.showSintesiAccounting && (
                          <OnlyWithFunction acceptedFunction="UTENTE_INTERNO">
                            <StyledButton
                              sx={{
                                marginLeft: '10px',
                                color: theme.palette.black.main,
                                backgroundColor: theme.palette.grey.light,
                                border: 'none',
                              }}
                              component={Link}
                              to="/sintesiAccounting"
                            >
                              <Typography variant="caption">
                                SINTESI ACCOUNTING
                              </Typography>
                            </StyledButton>
                          </OnlyWithFunction>)}
                          {props.showSintesiAccounting && (
                          <OnlyWithFunction acceptedFunction="NUOVA_ADV">
                            <StyledButton
                              sx={{
                                marginLeft: '10px',
                                color: theme.palette.black.main,
                                backgroundColor: theme.palette.grey.light,
                                border: 'none',
                              }}
                              component={Link}
                              to="/monitoring"
                            >
                              <Typography variant="caption">
                                MONITORAGGIO
                              </Typography>
                            </StyledButton>
                          </OnlyWithFunction>
                          
                        )}
                          {/* <OnlyWithFunction acceptedFunction="FUNZIONE_AML">
                            <StyledButton
                              sx={{
                                marginLeft: '10px',
                                color: theme.palette.black.main,
                                backgroundColor: theme.palette.grey.light,
                                border: 'none',
                              }}
                              component={Link}
                              to="/monitoring"
                            >
                              <Typography variant="caption">
                                MONITORAGGIO
                              </Typography>
                            </StyledButton>
                          </OnlyWithFunction> */}
                          
                        
                        {props.showDuplicaAdv && (
                          <OnlyWithFunction acceptedFunction="NUOVA_ADV">
                            <StyledButton
                              onClick={() => clickDuplicaAdvButton()}
                              sx={{
                                marginLeft: '20px',
                                color: theme.palette.black.main,
                                backgroundColor: theme.palette.grey.light,
                                border: 'none',
                              }}
                            >
                              <Typography
                                variant="caption"
                                color="black"
                                sx={{ fontSize: 10 }}
                              >
                                <b>NUOVA</b> ADV
                              </Typography>
                              <AddIcon
                                sx={{
                                  color: theme.palette.blue.main,
                                  fontSize: 20,
                                }}
                              ></AddIcon>
                            </StyledButton>
                          </OnlyWithFunction>

                        )}

                      </Box>

                      <Box
                        sx={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          // width: '100%',
                          // maxWidth: '1500px',

                          display: 'flex',
                          mt: '5px',
                          mr: '20px',
                        }}
                      >
                        {props.showLogout === false ? null : (
                          <StyledButton
                            sx={{
                              color: theme.palette.black.main,
                              backgroundColor: theme.palette.grey.light,
                              border: 'none',
                            }}
                            component={Link}
                            to="/"
                            onClick={handleLogout}
                          >
                            <Typography variant="caption">
                              DISCONNETTI
                            </Typography>
                          </StyledButton>
                        )}
                        {props.showProfile === false ? null : (
                          <StyledButton
                            component={Link}
                            to="/profilo-utente"
                            sx={{
                              marginLeft: '20px',
                              marginRight: '10px',
                              color: theme.palette.black.main,
                              backgroundColor: theme.palette.grey.light,
                              border: 'none',
                            }}
                          >
                            <Typography variant="caption">
                              {JSON.parse(localStorage.getItem('user'))}
                            </Typography>
                          </StyledButton>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <DuplicaADVModal
                open={openModalDuplicaAdv}
                idNodoPratica={props.idNodoPratica}
                praticaProperties={props.praticaProperties}
                handleClose={handleDuplicaAdvModalClose}
                addPraticaToDettaglio={props.addPraticaToDettaglio}
              ></DuplicaADVModal>
            </>
          )}
        </Grid>
      </AppBar>
    </div>
  )
}

export default Header
